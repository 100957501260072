<template>
  <div class="cntr">
    <input
      type="checkbox"
      :id="checkedId"
      class="hidden-xs-up"
      :checked="checkedStatus"
      @click="$emit('change')"
    >
    <label :for="checkedId" class="cbx"></label>
  </div>
</template>

<script>
export default {
  name: 'AnimateCheckBox',
  props: {
    checkedStatus: {
      type: Boolean,
      default: false
    },
    checkedId: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.$el.querySelector('input').id = this.checkedId
  }
}
</script>

<style lang="sass" scoped>
.cbx
  position: relative
  top: 1px
  width: 18px
  height: 18px
  border: 1px solid #c8ccd4
  border-radius: 3px
  vertical-align: middle
  transition: background 0.1s ease
  cursor: pointer
  display: block

.cbx:after
  content: ''
  position: absolute
  top: 2px
  left: 6px
  width: 5px
  height: 10px
  opacity: 0
  transform: rotate(45deg) scale(0)
  border-right: 2px solid #fff
  border-bottom: 2px solid #fff
  transition: all 0.3s ease
  transition-delay: 0.15s

.lbl
  margin-left: 5px
  vertical-align: middle
  cursor: pointer

#app input[id]:checked ~ .cbx
  border-color: transparent
  background: var(--theme_secondary)
  animation: jelly 0.6s ease

#app input[id]:checked ~ .cbx:after
  opacity: 1
  transform: rotate(45deg) scale(1)

.cntr
  position: relative
  padding: 0 12px

@keyframes jelly
  from
    transform: scale(1, 1)

  30%
    transform: scale(1.25, 0.75)

  40%
    transform: scale(0.75, 1.25)

  50%
    transform: scale(1.15, 0.85)

  65%
    transform: scale(0.95, 1.05)

  75%
    transform: scale(1.05, 0.95)

  to
    transform: scale(1, 1)

.hidden-xs-up
  display: none!important

</style>
